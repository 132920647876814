@import '../../../../../assets/styles/index.scss';

.bench-form__modal-footer {
    padding-bottom: 10px;
    text-align: center;
  
    .bench-form__modal-footer-cancel {
      color: $primary-light-green;
      cursor: pointer;
      font-weight: bold;
    }
  
    .bench-form__modal-footer-clear-fields {
      color: $primary-dark-green;
      cursor: pointer;
      font-weight: bold;
    }
  }