@import '../../../assets/styles/index.scss';

.map__spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  .ant-empty {
    color: $primary-dark-green;
    font-weight: 500;
  }
}

.pin-info-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 12px;
  width: 320px;
  background-color: $primary-dark-green;
  color: #fff;
  font-weight: bold;

  .close-button {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    z-index: 100;
    background-color: $primary-dark-green;
    font-weight: bold;
    border: 1px solid #fff;
    border-radius: 3px;
    font-size: 16px;
    padding: 5px 10px;
    outline: none;
  }

  .pin-bench-id {
    font-weight: bold;
    font-size: 16px;
  }

  .pin-info {
    font-size: 17px;
  }

  .pin-info-type {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 17px;
  }
}

.gm-style,
.gm-style-iw-c,
.gm-style-iw-d {
  padding: 0px !important;
  overflow: hidden !important;
}
