@import '../../../../assets/styles/index.scss';

.reports-form {
  .edit-tag {
    color: $primary-light-green;
    border: 1px solid $primary-light-green;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 13px;
    margin: 5px;
    margin-left: 0;

    .anticon-close {
      color: $primary-light-green;
    }
  }

  .site-tag-plus {
    background: $primary-dark-green;
    color: #fff;
    border: 1.5px solid $primary-dark-green-lighter;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    margin: 5px;
    margin-left: 0;
  }

  .ant-select,
  .ant-select .ant-select-selector {
    border-color: $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100% !important;

    &:hover {
      border-color: $primary-dark-green-lighter !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-search {
        input {
          &::placeholder {
            color: rgba(0, 84, 80, 0.35) !important;
          }
        }
      }
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  .ant-select-item-empty {
    color: $primary-light-green !important;
  }

  .reports-form__benches-tooltip-icon {
    margin-left: 5px;
  }

  .reports-form__error-text {
    color: $primary-error;
    font-weight: 500;
  }

  .reports-form__range-picker-container, .ant-form-item-has-error:hover {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $primary-dark-green;

    span {
      margin-left: 5px;
    }

    .ant-picker:hover,
    .ant-picker-focused {
      border-color: $primary-light-green !important;
    }

    .ant-picker {
      background: $primary-dark-green;
      border-color: $primary-dark-green;
      border-radius: 8px;
      width: 100%;

      &:focus,
      &:active,
      &:visited {
        border-color: $primary-light-green !important;
      }

      .ant-picker-input {
        input {
          color: #fff !important;

          &::placeholder {
            color: #ebf1f1;
          }
        }
      }

      .ant-picker-active-bar {
        background: $primary-light-green;
      }

      .ant-picker-range-separator {
        visibility: hidden;
      }

      .ant-picker-suffix {
        color: $primary-light-green;
      }

      .ant-picker-clear {
        background: $primary-dark-green;
        color: $primary-light-green;
      }
    }
  }

  .sensors-errors-container {
    padding-top: 10px;
  }

  .ant-form-item {
    display: block;
  }

  .reports-form__radio-button {
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .ant-input {
    border: 1px solid rgba(0, 84, 80, 0.35);
    color: $primary-dark-green;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px;
    margin-top: 5px;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  .ant-input:focus,
  .ant-input-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .reports-form__select-period {
    .ant-select-selector {
      border: 1px solid rgba(0, 84, 80, 0.35) !important;
      box-sizing: border-box !important;
      border-radius: 8px !important;
      color: $primary-dark-green;
      font-weight: 500;
    }
  }

  .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $primary-dark-green;
  }

  .ant-radio-inner::after {
    background: $primary-dark-green;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-dark-green;
    border-color: $primary-dark-green !important;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox {
    border-color: $primary-dark-green !important;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox-wrapper,
  .ant-checkbox-wrapper-checked {
    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-dark-green !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $primary-dark-green;
  }

  .ant-checkbox-inner {
    border: 1px solid $primary-dark-green;
  }

  label {
    color: $primary-dark-green;
    font-weight: bold;
  }

  label::after {
    content: '';
  }
}

@media screen and (max-width: 575px) {
  .reports-form__radio-button {
    padding-left: 0 !important;
  }
}

.label {
  color: #005450;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;
}
