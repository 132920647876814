@import "~antd/dist/antd.css";
@import "./assets/styles/index.scss";

input:-internal-autofill-selected {
  background: $primary-dark-green !important;
}

.ant-popover-content {
  cursor: url("./assets/icons/black-mouse-pointer.svg"), auto;
}

.ant-layout {
  background: $layout-background no-repeat fixed center !important;
  padding: 0;

  .ant-layout-content {
    padding: 0 !important;
  }
}

.ant-tabs-nav::before {
  border-bottom: 0 !important;
}

.ant-tabs-nav-list {
  .ant-tabs-tab {
    h3 {
      color: $primary-dark-green;
      margin: 0;
    }
  }

  .ant-tabs-tab-active {
    h3 {
      font-weight: bold;
    }
  }

  .ant-tabs-ink-bar {
    background: $primary-light-green;
    height: 4px !important;
  }
}

.layout {
  height: 100%;
  background: $layout-background no-repeat cover;

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s;
  }

  .site-layout .site-layout-background {
    background: $layout-background;
  }
}

.ant-select-dropdown {
  background: $primary-dark-green;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(0, 37, 35, 0.3);

  .ant-select-item {
    color: #fff;

    &:hover {
      color: $primary-light-green;
      background: $primary-dark-green-light !important;
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled),
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: $primary-dark-green-light !important;
  }
}
