@import '../../../../assets/styles/index.scss';

.content-form {
  .ant-modal-header {
    color: $primary-dark-green !important;
  }

  .content-form__tree-container {
    overflow-y: scroll;
    height: 100%;
    max-height: 400px;
    padding-bottom: 20px;
    flex-flow: wrap;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background: $primary-dark-green;
  }

  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: $primary-light-green !important;
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    border-color: $primary-light-green !important;
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    border-color: $primary-light-green !important;
    background: $primary-dark-green;
  }

  .ant-tree-checkbox-checked::after {
    border: 1px solid $primary-light-green;
  }

  .ant-tree-switcher {
    color: $primary-dark-green;
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: $primary-dark-green;
    border-color: $primary-dark-green !important;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-tree-checkbox {
    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-tree-checkbox-wrapper,
  .ant-tree-checkbox-wrapper-checked {
    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-tree-title {
    color: $primary-dark-green;
    font-weight: 500;
  }

  .ant-form-item {
    display: block;
  }

  .ant-input {
    border: 1px solid $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  .ant-picker {
    width: 100%;
    border-radius: 8px;
    border-color: $primary-dark-green-lighter !important;
  }

  .ant-picker-active-bar {
    background: $primary-light-green;
  }

  // .ant-picker:hover,
  // .ant-picker-focused {
  //   border-color: $primary-light-green !important;
  // }

  .ant-upload {
    border-radius: 8px;
    border-color: $primary-dark-green-lighter;
  }

  .content-form__file-name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .anticon-delete {
      color: $primary-light-green;
      font-size: 14px;
      padding: 5px;
      border-radius: 8px;

      &:hover {
        color: red;
        background: rgba(255, 0, 0, 0.24);
        cursor: pointer;
      }
    }
  }

  .content-form__dragger-paragraph {
    color: $primary-dark-green;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: $primary-light-green;
      text-decoration: underline;
    }
  }

  .content-form__dragger-warning {
    color: red;
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: rgba(0, 84, 80, 0.35);
  }

  .ant-select,
  .ant-select .ant-select-selector {
    border-color: $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100% !important;

    &:hover {
      border-color: $primary-dark-green-lighter !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-search {
        input {
          &::placeholder {
            color: rgba(0, 84, 80, 0.35) !important;
          }
        }
      }
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  .ant-select-item-empty {
    color: $primary-light-green !important;
  }

  .ant-input-focused {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  label {
    cursor: url('../../../../assets/icons/black-mouse-pointer.svg'), auto;
    color: $primary-dark-green;
    font-weight: bold;
    width: 100%;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  label::after {
    content: '';
  }

  .user-branch-list_cards-container {
    overflow-y: scroll;
    height: 100%;
    max-height: 133px;
    padding: 10px;
    flex-flow: wrap;

    .client-branch-list__empty-bench-list {
      margin-top: 10px;
      color: $primary-light-green;
      box-shadow: 0 2px 8px $primary-light-green-lighter;
      border-radius: 8px;
      padding: 10px;
      font-weight: 400;
    }

    .users-branch-list__card {
      margin: 10px 0;
      box-shadow: 0px 5px 10px rgba(0, 84, 80, 0.1);

      .users-branch-list__table {
        width: 100%;
        background: #fff;
        margin: 0 !important;

        .users-branch-list__table-cell {
          color: $primary-dark-green;
          font-weight: 500;

          .users-branch-list__remove-branch-button {
            cursor: pointer;
          }
        }
      }

      .ant-card-body {
        padding: 10px;
      }
    }
  }
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: #fff !important;

  &:hover {
    background: $primary-light-green;
  }
}

.ant-picker-time-panel-cell .ant-picker-time-panel-cell-selected {
  background: green !important;
}

.ant-picker-header {
  .ant-picker-header-view {
    color: #fff;
  }
}

.ant-picker-footer {
  button {
    background: $primary-light-green;
    color: #fff;
    border-color: $primary-light-green;

    &:hover {
      background: $primary-light-green-light;
      border: 1px solid $primary-light-green;
    }
  }
}
