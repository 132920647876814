@import '../../../../assets/styles/index.scss';

.branch-client-form {
  padding-bottom: 20px;

  .users-list__spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;

    .ant-empty {
      color: $primary-dark-green;
      font-weight: 500;
    }
  }

  .branch-client-form__bench-id-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .anticon {
      color: $primary-light-green;
      cursor: pointer;
    }
  }

  .branch-bench-list__empty-bench-list {
    margin-top: 10px;
    color: $primary-light-green;
    box-shadow: 0 2px 8px $primary-light-green-lighter;
    border-radius: 8px;
    padding: 10px;
    font-weight: 400;
  }

  .branch-bench-list__cards-container {
    overflow-y: scroll;
    height: 100%;
    max-height: 300px;
    padding: 10px;
    flex-flow: wrap;

    .branch-bench-list__card {
      margin: 10px 0;
      box-shadow: 0px 5px 10px rgba(0, 84, 80, 0.1);

      .branch-bench-list__table {
        width: 100%;
        background: #fff;
        margin: 0 !important;

        .branch-bench-list__table-cell {
          color: $primary-dark-green;
          font-weight: 500;

          .branch-bench-list__remove-branch-button {
            cursor: pointer;
          }
        }
      }

      .ant-card-body {
        padding: 10px;
      }
    }
  }

  .ant-form-item-label {
    width: 100%;
  }

  .ant-input {
    border: 1px solid $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  .ant-input:focus,
  .ant-input-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-select,
  .ant-select .ant-select-selector {
    border-color: $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100% !important;

    &:hover {
      border-color: $primary-dark-green-lighter !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-search {
        input {
          &::placeholder {
            color: rgba(0, 84, 80, 0.35) !important;
          }
        }
      }
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  .ant-select-item-empty {
    color: $primary-light-green !important;
  }

  .ant-input-focused {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .ant-form-item {
    display: block;
  }

  label {
    color: $primary-dark-green;
    font-weight: bold;
    width: 100%;
  }

  label::after {
    content: '';
  }

  input {
    border: 1px solid $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px 11px;
    width: 100%;
    outline: none;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }
}

.ant-select-item-empty {
  color: #fff;
  text-align: center;
}
