/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 6px rgba(51, 204, 102, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #005450;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005450;
  box-shadow: inset 0 0 6px rgba(51, 204, 102, 0.5);
}

::-webkit-input-placeholder {
  color: rgba(0, 84, 80, 0.35);
}

html,
body {
  cursor: url('./assets/icons/black-mouse-pointer.svg'), auto;
  margin: 0;
  min-width: 450px;
  background: #ebf1f1 no-repeat fixed center !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Cambay', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
