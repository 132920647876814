@import '../../../assets/styles';

.btn-primary {
    background: $primary-light-green;
    box-shadow: 0 5px 8px $primary-light-green-lighter;
    border: 1px solid $primary-light-green;
    border-radius: 8px;
    color: #fff;

    &:hover, &:focus, &:active {
        background: $primary-light-green-light;
        border: 1px solid $primary-light-green;
        color: #fff;
    };

    &.btn-danger {
        background-color: $primary-red;
        border: 1px solid $primary-red;
        box-shadow: 0 5px 8px $primary-red-lighter;
    }
    &.btn-transparent {
        background: none;
        font-weight: bold;
        color: $primary-light-green;
        border: none;
        box-shadow: none;
    }
};

.ant-btn-lg {
    font-size: 14px;
}