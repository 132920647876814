@import '../../../../assets/styles/index.scss';

.delete-bench-container {
  padding-bottom: 10px;

  .delete-bench-form-footer {
    display: flex;
    flex-direction: row-reverse;
    .delete-bench-submit-btn {
      margin-left: 10px;
    }
  }
}