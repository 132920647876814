@import '../../../assets/styles/index.scss';

.branch-list__container {
  height: 100%;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;

  .branch-list__cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    .branch-list__card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: stretch;
      padding: 5px;
      text-align: center;
      margin: 5px;

      .branch-list__card {
        text-align: center;
        width: 240px;
        min-height: 190px;
        max-height: 240px;

        .ant-card-body {
          padding: 15px;
        }

        .branch-list__name-action-btn-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .branch-list__name {
            color: $primary-dark-green;
            font-weight: 500;
            text-align: left;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .branch-list__benches-label {
          display: flex;
          margin-top: 10px;
          color: $primary-dark-green;
        }

        .branch-list__benches-container {
          margin-top: 5px;
          overflow-y: scroll;
          height: 100%;
          max-height: 100px;
          padding: 10px;
          flex-flow: wrap;

          .branch-list__benches-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 7px;

            .branch-list__benchId {
              color: $primary-dark-green;
              font-weight: 500;
            }

            .branch-list__status-tag {
              display: flex;
              justify-content: center;
            }
          }
        }

        .branch-list__empty-bench-list {
          margin-top: 10px;
          color: $primary-light-green;
          box-shadow: 0 2px 8px $primary-light-green-lighter;
          border-radius: 8px;
          padding: 10px;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .branch-list__container {
    display: flex;
    justify-content: center;

    .branch-list__cards-container {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 676px) {
  .branch-list__container {
    .branch-list__cards-container {
      justify-content: center;

      .branch-list__card-container {
        width: 80%;

        .branch-list__card {
          width: 100%;
        }
      }
    }
  }
}
