@import '../../../../../assets/styles/index.scss';

.download-reports-form__modal-footer {
    margin: 0 10px;
    min-height: 80px;

    .ant-form-item {
        display: block;
        text-align: left;
    }

    .download-reports-form__modal-footer-cancel-container {
        text-align: center;

        .download-reports-form__modal-footer-cancel {
            color: $primary-light-green;
            cursor: pointer;
            font-weight: bold;
        }
    }

    .download-reports-form__select-period {
        .ant-select-selector {
            border: 1px solid rgba(0, 84, 80, 0.35) !important;
            box-sizing: border-box !important;
            border-radius: 8px !important;
            color: $primary-dark-green;
            font-weight: 500;
        }
    }

    label {
        color: $primary-dark-green;
        font-weight: bold;
    }
}