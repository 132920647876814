@import '../../../../assets/styles/index.scss';

.edit-content-form {
  .ant-form-item {
    display: block;
  }

  label {
    cursor: url('../../../../assets/icons/black-mouse-pointer.svg'), auto;
    color: $primary-dark-green;
    font-weight: bold;
    width: 100%;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  label::after {
    content: '';
  }
}

.edit-content-form__range-picker-container {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $primary-dark-green;

  span {
    margin-left: 5px;
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: $primary-light-green !important;
  }

  .ant-picker {
    background: $primary-dark-green;
    border-color: $primary-dark-green;
    border-radius: 8px;
    width: 100%;

    &:focus,
    &:active,
    &:visited {
      border-color: $primary-light-green !important;
    }

    .ant-picker-input {
      input {
        color: #fff !important;

        &::placeholder {
          color: #ebf1f1;
        }
      }
    }

    .ant-picker-active-bar {
      background: $primary-light-green;
    }

    .ant-picker-range-separator {
      visibility: hidden;
    }

    .ant-picker-suffix {
      color: $primary-light-green;
    }

    .ant-picker-clear {
      background: $primary-dark-green;
      color: $primary-light-green;
    }
  }
}
