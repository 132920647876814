@import '../../../assets/styles/index.scss';

.sider {
  background: $primary-dark-green;
  position: relative;
  // min-height: 900px;

  .ant-layout-sider-children {
    background-color: $primary-dark-green;
    height: 0px;
    position: sticky !important;
    top: 0 !important;
  }

  .c2c-logo {
    width: 100%;
    height: 10%;
    padding: 16px;
    border-radius: 16px;
  }

  .trigger {
    font-size: 25px;
    margin-top: 20px;
    padding-left: 18px;

    &:hover {
      transition: 0.3s;
      color: $primary-light-green;
    }
  }

  .sider__menu {
    background: $primary-dark-green;
    margin-top: 40px;
    padding: 0;

    .sider__menu-item {
      display: flex;
      align-items: center;
      padding: 15px !important;
      font-size: 20px;
      color: $sider-menu-item-text;
      list-style: none;

      .sider__menu-item-title-container {
        display: flex;
        opacity: 1;
        margin-left: 5px;
      }

      .anticon {
        margin-right: 30px;
        font-size: 20px;
        color: $sider-menu-item-text;
      }

      &:hover {
        transition: 0.3s;
        color: $sider-menu-item-text-hover;

        .anticon {
          transition: 0.3s;
          color: $primary-light-green;
        }
      }

      .sider__menu-item-title-hidden {
        display: none;
      }
    }

    .ant-menu-item-selected {
      background: $primary-dark-green !important;
      border-right: 6px solid $primary-light-green;

      .anticon {
        transition: 0.3s;
        color: $primary-light-green;
      }

      .sider__menu-item-title-active {
        transition: 0.3s;
        color: $sider-menu-item-text-active;
      }
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
      .ant-menu-item-selected {
      transition: 0.3s;
      background: $primary-dark-green !important;
      color: $primary-light-green !important;
    }
  }

  .sider__logout {
    position: relative;
    margin-left: 5px;
    display: flex;
    align-items: center;
    padding: 15px !important;
    font-size: 20px;
    color: #fff;
    cursor: pointer;

    .anticon {
      margin-right: 30px;
      font-size: 20px;
    }

    &:hover {
      transition: 0.3s;
      color: $primary-light-green;

      .anticon {
        transition: 0.3s;
        color: $primary-light-green;
      }
    }

    .sider__menu-logout-text-hidden {
      display: none;
    }
  }
}

@media screen and (max-width: 50px) {
  .sider-not-collapsed {
    min-width: 50px !important;
  }
}

@media screen and (max-width: 430px) {
  .sider-not-collapsed {
    min-width: 430px !important;
  }
}

@media screen and (max-width: 570px) {
  .sider-not-collapsed {
    min-width: 100% !important;
  }
}

@media screen and (min-width: 1000px) {
  .sider {
    .sider__menu {
      .sider__menu-item {
        font-size: 20px;

        .anticon {
          font-size: 20px;
        }
      }
    }
  }
}
