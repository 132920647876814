.list__container {
    overflow: auto;
    max-height: 300px;
    
    .notifications-list {
        border: 0;
    
        .notifications-list__item {
            color: #fff;
            border: 0;
            border-bottom: 1px solid #fff;
            padding-left: 0 !important;
        }
    }
}