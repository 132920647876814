@import '../../../assets/styles/index.scss';

.dashboard-container {
  .dashboard__spinner-container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent;

    .loader {
      left: 50%;
      margin-left: 2em;
    }

    .loader,
    .loader:after {
      display: block;
      position: absolute;
      top: 50%;
    }
  }

  .dashboard-container__alarms-card {
    h1 {
      color: $primary-dark-green;
    }
  }
  .dashboard-container__requests-card {
    h1 {
      color: $primary-dark-green;
    }
  }
  .dashboard-container__history-card {
    h1 {
      color: $primary-dark-green;
    }
  }
  .dashboard-container__map-card-container {
    margin-top: 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dashboard__add-content-modal {
  max-width: 600px;
  width: 100% !important;
  min-width: 400px;

  .dashboard__add-content-modal-title {
    margin-left: 0 !important;
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}

.dashboard__reload-page-modal {
  max-width: 600px;
  width: 100% !important;
  min-width: 400px;

  .dashboard__reload-page-modal-content {
    padding: 24px 0;
    color: $primary-dark-green;
    font-size: 15px;
    font-weight: 500;

    .dashboard__reload-page-modal-close {
      color: $primary-light-green;
      margin-top: 20px;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }
  }

  .dashboard__reload-page-modal-title {
    margin-left: 0 !important;
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 991px) {
  .dashboard-container__alarms-card-container,
  .dashboard-container__requests-card-container,
  .dashboard-container__history-card-container {
    margin: 10px 0;
  }
}

@media screen and (max-width: 720px) {
  .dashboard-container-map-card {
    transition: 0.3s;
    height: 300px !important;
  }
}
