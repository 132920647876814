@import '../../../assets/styles/index.scss';

.language-picker__container {
  display: flex;
  align-items: center;

  .language-picker__dropdown-button {
    border: none;

    .ant-btn:nth-child(2) {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      background-color: transparent;
      width: 100%;
      height: 48px;
      margin-left: -20px;
      margin-right: 20px;
    }
  }
}

.language-picker__language-list {
  background: $primary-dark-green;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(0, 37, 35, 0.3);

  .ant-dropdown-menu-item,
  a {
    color: #fff;

    &:hover {
      color: $primary-light-green;
      background: $primary-dark-green-light !important;
    }
  }

  .language-picker__language-label-container {
    display: flex;
    justify-content: center;
  }

  .language-picker__language-list-divider {
    margin: 0;
    height: 1px !important;
    width: auto;
    min-width: auto;
    background-color: #fff;
  }
}

.language-picker__flag-icon-container {
  display: flex;
  align-items: center;
  background-color: $primary-dark-green;
  color: #fff;
  font-size: 15px;
  border-radius: 8px;

  span {
    margin-left: 5px;
    padding: 6px 12px;
  }

  &:hover {
    color: $primary-light-green;
  }
}
