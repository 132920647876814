@import './colors.scss';

.content-form__add-content-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 40px;

  .hint-icon {
    float: right;
    margin-right: 15px;
    color: $primary-light-green;
    cursor: pointer;
  }
}

.content-screen__add-content-modal {
  max-width: 900px;
  width: 100% !important;
  min-width: 400px;

  .content-screen__add-content-modal-title {
    margin-left: 0 !important;
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 570px) {
  .content-form__add-content-button-container {
    display: flex;
    justify-content: center;

    .content-form__add-content-button {
      width: 100%;
    }
  }
}
