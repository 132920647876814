@import '../../../assets/styles/index.scss';

.notifications__container {
  margin: auto 20px auto 20px;

  .notifications__bell-button {
    border: 0;
    background: $primary-dark-green;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-icon-bell {
      text-align: center;
      display: flex;
    }

    &:hover {
      color: $primary-light-green;
    }
  }
}

.notifications__more {
  background: $primary-dark-green;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(0, 37, 35, 0.3);

  .ant-dropdown-menu-item,
  a {
    color: #fff;

    &:hover {
      color: $primary-light-green;
      background: $primary-dark-green-light !important;
    }
  }

  .notifications__more-divider {
    margin: 0;
    height: 1px !important;
    width: auto;
    min-width: auto;
    background-color: #fff !important;
  }
}

.notifications__title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-dropdown-trigger {
    transform: rotate(90deg);
    background: $primary-dark-green;
    border: 0;
    color: #fff;
  }
}

.ant-popover-content {
  max-width: 500px;

  ::-webkit-scrollbar-track {
    background-color: $primary-dark-green;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $primary-light-green;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.ant-popover-inner {
  background-color: $primary-dark-green !important;
  border-radius: 16px;

  .ant-popover-title {
    color: #fff;
    border-bottom: 0;
    padding: 12px 16px;
    padding-bottom: 0;
  }

  .ant-popover-inner-content {
    color: #fff;
  }
}

@media (max-width: 370px) {
  .notifications__container {
    margin: 0;
  }
}
