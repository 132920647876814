@import '../../../assets/styles/index.scss';

.benches__container {
  .ant-card-body {
    padding: 12px 12px 4px 12px;
    min-height: 100px;
  }

  .bench_action_buttons_container {
    display: flex;
    flex-direction: row-reverse;
    button {
      margin-left: 10px;
    }
  }

  .data_container {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    justify-content: space-between;
  }

  .benches__card-title {
    font-weight: 500;
    font-size: 20px;
    color: $primary-dark-green;
    line-height: 25px;
    display: flex;
    align-items: flex-end;
  }

  .bench_data_section_title {
    color: $primary-dark-green;
    font-weight: bold;
  }

  .bench_data_section_units {
    color: $primary-dark-green;
    font-size: 14px;
    font-weight: bold;
  }

  .bench_status_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bench_status_icon {
      width: 14px;
      height: 14px;
      margin-top: 8px;
    }
  }

  .data_wrapper {
    display: flex;
    align-items: center;
    &:hover {
      .data_value {
        color: $primary-light-green;
      }
      .data_unit {
        color: $primary-light-green;
      }
      .usage-summary-icon {
        transition-duration: 0.3s;
        box-shadow: 0 8px 10px 6px #bbf6d1;
      }
    }
    .usage-summary-icon {
      border-radius: 14px;
      height: 100%;
    }
  }

  .benches__card-title_usage_summary {
    font-weight: 500;
    font-size: 20px;
    color: $primary-dark-green;
    line-height: 25px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
  }

  .data_value {
    font-size: 16px;
    color: $primary-dark-green;
  }

  .data_unit {
    font-size: 12px;
    color: $primary-dark-green;
    font-weight: bold;
  }
}

.benches__config-modal {
  max-width: 550px;
  width: 100% !important;
  min-width: 400px;

  .benches__config-modal-title {
    color: $primary-dark-green;
    margin-top: 10px;
  }
}

@media screen and (min-width: 50px) {
  .benches__container {
    .benches__card-title {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 430px) {
  .benches__container {
    .benches__card-title {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 570px) {
  .benches__container {
    .benches__card-title {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 720px) {
  .benches__container {
    .benches__card-title {
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .benches__container {
    .benches__card-title {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .bench_status_wrapper {
    align-items: flex-start !important;

    .bench_status_icon {
      margin-left: 12px;
    }
  }
}
