@import '../../../assets//styles/index.scss';

.edit-content-form__modal {
  max-width: 600px;
  width: 100% !important;
  min-width: 400px;

  .edit-content-form__modal-title {
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}
