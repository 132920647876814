@import '../../../../assets/styles/index.scss';

.branch-form {
  .ant-form-item {
    display: block;
  }

  .ant-input {
    border: 1px solid rgba(0, 84, 80, 0.35);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  .ant-input:focus,
  .ant-input-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  label {
    color: $primary-dark-green;
    font-weight: bold;
    width: 100%;
  }

  label::after {
    content: '';
  }
}
