@import '../../../../../assets/styles/index.scss';

.clients-form__modal-footer {
    padding: 20px 0;
  
    .clients-form__modal-footer-cancel {
      color: $primary-light-green;
      cursor: pointer;
      font-weight: bold;
    }
  
    .clients-form__modal-footer-clear-fields {
      color: $primary-dark-green;
      cursor: pointer;
      font-weight: bold;
    }
  }