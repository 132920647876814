// primary colors
$primary-light-green: #33cc66;
$primary-light-green-light: rgba($primary-light-green, 0.7);
$primary-light-green-lighter: rgba($primary-light-green, 0.5);
$primary-light-green-lightest: rgba($primary-light-green, 0.3);

$primary-dark-green: #005450;
$primary-dark-green-light: rgba($primary-dark-green, 0.7);
$primary-dark-green-lighter: rgba($primary-dark-green, 0.5);
$primary-dark-green-lightest: rgba($primary-dark-green, 0.3);

$primary-darker-green: #1b4845;
$primary-darker-green-light: rgba($primary-darker-green, 0.7);
$primary-darker-green-lighter: rgba($primary-darker-green, 0.5);
$primary-darker-green-lightest: rgba($primary-darker-green, 0.3);

$primary-red: #d90429;
$primary-red-light: rgba($primary-red, 0.7);
$primary-red-lighter: rgba($primary-red, 0.5);
$primary-red-lightest: rgba($primary-red, 0.3);

$primary-error: #ff4d4f;

// secondary colors
$secondary-dark: #333333;
$secondary-light: #999999;

$layout-background: #ebf1f1;

$sider-menu-item-text: #337673;
$sider-menu-item-text-hover: #ffffff;
$sider-menu-item-text-active: #ffffff;

$modal-background: #ffffff;
