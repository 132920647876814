@import '../../../../assets/styles/index.scss';

.clients-form {
  .ant-modal-header {
    color: $primary-dark-green !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-dark-green;
    border-color: $primary-dark-green !important;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox {
    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox-wrapper,
  .ant-checkbox-wrapper-checked {
    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-dark-green !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $primary-dark-green;
  }

  .ant-checkbox-inner {
    border: 1px solid $primary-dark-green;
  }

  .ant-form-item-label {
    width: 100%;

    .clients-form__rp-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .anticon {
        color: $primary-light-green;
        cursor: pointer;
      }
    }
  }

  .clients-form__rp-container {
    .ant-form-item {
      .ant-form-item-label {
        .ant-form-item-label {
          label {
            display: block !important;
          }
        }
      }
    }
  }

  .clients-form__bench-id-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .clients-form__input-group {
    display: flex;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    .ant-input {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .ant-form-item {
    display: block;
  }

  .ant-input {
    border: 1px solid rgba(0, 84, 80, 0.35);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  .ant-input:focus,
  .ant-input-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .clients-form__type-select {
    .ant-select-selector {
      border: 1px solid rgba(0, 84, 80, 0.35) !important;
      box-sizing: border-box !important;
      border-radius: 8px !important;
    }
  }

  .clients-form__phone-select {
    .ant-select-selector {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 84, 80, 0.35) !important;
      box-sizing: border-box !important;
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      height: 100%;
    }

    input {
      border-top-right-radius: 10px !important;
    }
  }

  label {
    cursor: url('../../../../assets/icons/black-mouse-pointer.svg'), auto;
    color: $primary-dark-green;
    font-weight: bold;
    width: 100%;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  label::after {
    content: '';
  }
}
