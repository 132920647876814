.download-reports-form__container {
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 30px;

    .download-reports-form__file-preview {
        width: 355px;
        height: 500px;
        background: #FFFFFF;
        border-radius: 0px;
    }
}