@import './colors.scss';

.benches-screen {
  .benches-screen__tabs {
    width: 100%;
    padding: 0 10px;
  }

  .benches-screen__add-bench-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;

    .benches-screen__select-export-container, .benches-screen__filter-period-container {
      display: flex;

      .benches-screen__select-period, .benches-screen__filter-period {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $primary-dark-green;
        margin-right: 30px;

        .datepicker-container {
          display: flex;
          align-items: center;
        }

        .ant-picker-input input {
          cursor: pointer;
        }

        span {
          margin-left: 5px;
          cursor: pointer;
        }

        .done-btn {
          margin-left: 5px;
        }

        .ant-picker:hover,
        .ant-picker-focused {
          border-color: $primary-light-green !important;
        }

        .ant-picker {
          background: $primary-dark-green;
          border-color: $primary-dark-green;
          border-radius: 8px;

          &:focus,
          &:active,
          &:visited {
            border-color: $primary-light-green !important;
          }

          .ant-picker-input {
            input {
              color: #fff !important;

              &::placeholder {
                color: #ebf1f1;
              }
            }
          }

          .ant-picker-active-bar {
            background: $primary-light-green;
          }

          .ant-picker-range-separator {
            visibility: hidden;
          }

          .ant-picker-suffix {
            color: $primary-light-green;
          }

          .ant-picker-clear {
            background: $primary-dark-green;
            color: $primary-light-green;
          }
        }
      }

      .benches-screen__export-reports {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $primary-dark-green;
        cursor: pointer;
        margin-right: 30px;

        span {
          margin-left: 5px;
        }
      }
    }
  }
}

.ant-picker-range-arrow::after {
  border: 5px solid $primary-dark-green !important;
}

.ant-picker-panel-container {
  background: $primary-dark-green;

  .ant-picker-header {
    border-bottom-color: $primary-light-green;

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn {
      color: #fff;

      &:hover {
        color: $primary-light-green;
      }
    }

    .ant-picker-header-view {
      .ant-picker-month-btn,
      .ant-picker-year-btn {
        color: #fff;

        &:hover {
          color: $primary-light-green;
        }
      }
    }
  }

  .ant-picker-cell-disabled::before {
    background: $primary-dark-green !important;
  }

  .ant-picker-range-arrow {
    background: $primary-dark-green !important;
  }

  table,
  tr,
  td {
    background: $primary-dark-green;
    color: #fff;
  }

  .ant-picker-cell:hover {
    .ant-picker-cell-inner {
      background: $primary-light-green;

      &:hover {
        background: $primary-light-green !important;
      }
    }
  }

  table {
    tr {
      td:hover {
        .ant-picker-cell-inner {
          background: $primary-light-green !important;
        }
      }
    }
  }

  td:hover {
    .ant-picker-cell-inner {
      background: $primary-light-green;

      &:hover {
        background: $primary-light-green !important;
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 50px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: $primary-light-green-lighter !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: $primary-light-green-lighter !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary-light-green !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid $primary-light-green !important;
  }

  .ant-picker-cell-inner {
    border-radius: 50px;

    &:hover {
      background: $primary-light-green !important;
    }
  }
}

.benches-screen__add-bench-modal {
  max-width: 1100px;
  width: 100% !important;
  min-width: 400px;

  .benches-form__add-bench-modal-title {
    margin-left: 0 !important;
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}
.benches-screen__reports-modal {
  max-width: 600px;
  width: 100% !important;
  min-width: 400px;

  .benches-form__reports-modal-title {
    margin-left: 0 !important;
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}
.benches-screen__temp-offset-modal {
  max-width: 350px;
  width: 100% !important;
  min-width: 200px;
}

.benches-screen__download-reports-modal {
  max-width: 600px;
  width: 100% !important;
  min-width: 400px;

  .ant-modal-body {
    background: rgba(0, 84, 80, 0.1);
  }
}

@media screen and (max-width: 590px) {
  .benches-screen__add-bench-button-container {
    flex-direction: column;

    .benches-screen__select-export-container {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      .benches-screen__select-period,
      .benches-screen__export-reports {
        margin-right: 5px !important;
      }
    }

    .add-bench-btn {
      width: 100%;
    }
  }

  .benches-screen__tabs {
    margin-top: 20px;
  }
}
