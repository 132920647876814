@import "./colors.scss";

.users-screen__spinner-container {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;

  .loader {
    left: 50%;
    margin-left: 2em;
  }

  .loader,
  .loader:after {
    display: block;
    position: absolute;
    top: 50%;
  }
}

.users-list__tabs {
  .ant-tabs-nav {
    padding: 0 10px;
  }
}

.clients-form__add-user-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;

  .hint-icon {
    float: right;
    margin-right: 15px;
    color: $primary-light-green;
    cursor: pointer;
  }
}

.clients-form__modal {
  max-width: 800px;
  width: 100% !important;
  min-width: 400px;

  .clients-form__modal-title {
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}

.branch-form__modal {
  max-width: 500px;
  width: 100% !important;
  min-width: 400px;

  .branch-form__modal-title {
    color: $primary-dark-green;
    margin-top: 10px;
    margin-left: 10px;
  }
}
.client-branch-form__modal {
  max-width: 500px;
  width: 100% !important;
  min-width: 400px;

  .client-branch-form__modal-title {
    color: $primary-dark-green;
    margin-top: 10px;
  }
}
.branch-client-form__modal {
  max-width: 500px;
  width: 100% !important;
  min-width: 400px;

  .branch-client-form__modal-title {
    color: $primary-dark-green;
    margin-top: 10px;
  }
}

.users-list__tabs {
  width: 100%;
}

@media screen and (max-width: 570px) {
  .clients-form__add-user-button-container,
  .benches-screen__add-bench-button-container {
    display: flex;
    justify-content: center;

    .clients-form__add-user-button {
      width: 100%;
    }
  }

  .users-list__tabs {
    margin-top: 20px;
  }
}
