@import '../../../assets//styles/index.scss';

.ant-modal {

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-close {
      .anticon-close {
        font-size: 12px;
        color: $primary-dark-green;
      }
    }
  
    .ant-modal-header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 0;
    }

    .ant-modal-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .ant-modal-footer {
      border-top: 0 !important;
    }
  }
}

.ant-modal-mask {
  background: $primary-dark-green-lighter;
}