@import '../../../assets/styles/index.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  background: $layout-background;
  height: 100px;

  .header__logo-container {
    display: flex;
    align-items: center;
  }

  .header__content-container {
    display: flex;

    .header__user-info-container {
      display: flex;
      align-items: center;

      .header__user-info-text {
        display: flex;
        flex-direction: column;
        line-height: 1.6;
        justify-content: center;
        margin-right: 15px;
        text-align: right;

        .header__user-info-text-name {
          font-size: 15px;
          font-weight: bold;
          color: $primary-dark-green;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .header__user-info-text-role {
          font-size: 12px;
          color: $primary-dark-green;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .header__user-avatar {
      margin-right: -65px;
    }
  }
}

@media (max-width: 1160px) {
  .header__user-info-text {
    max-width: 200px !important;
  }
}

@media (max-width: 1160px) {
  .header__user-info-text {
    max-width: 150px !important;
  }
}

@media (max-width: 1050px) {
  .header__user-info-text {
    max-width: 100px !important;
  }
}

@media (max-width: 900px) {
  .header__logo-container img {
    display: none !important;
  }
}

@media (max-width: 860px) {
  .header__user-info-text {
    max-width: 100% !important;
  }
}

@media (max-width: 520px) {
  .header {
    justify-content: center;
    height: auto;
    margin-top: 10px;

    .header__content-container {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
    }

    .header__user-info-container {
      justify-content: flex-end;
      .header__search-box-container {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: 440px) {
  .header__user-info-text {
    max-width: 200px !important;
  }
}

@media (max-width: 370px) {
  .header {
    .header__content-container {
      .header__user-info-container {
        transition: 0.3s;
        justify-content: space-between;

        .header__user-info-text {
          max-width: 100px !important;
        }

        .header__user-avatar {
          margin-right: -100px;
        }
      }
    }
  }
}
