@import '../../../../assets/styles/index.scss';

.request-form {
  .request-form__content-title {
    color: $primary-dark-green;
  }

  .ant-form-item {
    display: block;
  }

  .ant-input {
    border: 1px solid $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  label {
    cursor: url('../../../../assets/icons/black-mouse-pointer.svg'), auto;
    color: $primary-dark-green;
    font-weight: bold;
    width: 100%;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  label::after {
    content: '';
  }

  .request-form__preview-file-container {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      max-width: 500px;
      width: 100%;
      max-height: 600px;
      height: 100%;
    }

    video {
      max-width: 500px;
      width: 100%;
      max-height: 600px;
      height: 100%;
    }
  }

  .ant-select,
  .ant-select .ant-select-selector {
    border-color: $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100% !important;
    color: $primary-dark-green;

    &:hover {
      border-color: $primary-dark-green-lighter !important;
    }
  }

  .ant-select-arrow {
    color: $primary-dark-green;
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-search {
        input {
          &::placeholder {
            color: rgba(0, 84, 80, 0.35) !important;
          }
        }
      }
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  .ant-select-item-empty {
    color: $primary-light-green !important;
  }
}
