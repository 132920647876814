@import '../../../../assets/styles/index.scss';

.bench-form {
  .ant-input-number {
    border: none !important;
  }

  .ant-input-number-focused {
    outline: none !important;
  }

  .bench-form__bench-id-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .anticon {
      color: $primary-light-green;
      cursor: pointer;
    }
  }

  .bench-form__location-search {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    max-width: 400px;
    z-index: 1000;
    padding: 0 15px;
  }

  .bench-form__sensors-title {
    color: $primary-dark-green;
  }

  .bench-form__location-input-label-night {
    color: $primary-light-green;
  }
  .bench-form__location-input-label-day {
    color: $primary-dark-green;
  }
  .ant-form-item-label {
    width: 100%;
  }

  .ant-input {
    border: 1px solid $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  .ant-select,
  .ant-select .ant-select-selector {
    border-color: $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100% !important;

    &:hover {
      border-color: $primary-dark-green-lighter !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-search {
        input {
          &::placeholder {
            color: rgba(0, 84, 80, 0.35) !important;
          }
        }
      }
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  .ant-select-item-empty {
    color: $primary-light-green !important;
  }

  .ant-input-focused {
    border-color: $primary-light-green !important;
    -webkit-box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
    box-shadow: 0 0 0 1px rgba(51, 204, 102, 0.5);
  }

  .ant-input:focus,
  .ant-input-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .ant-form-item {
    display: block;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-dark-green;
    border-color: $primary-dark-green !important;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox {
    border-color: $primary-dark-green !important;

    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox-wrapper,
  .ant-checkbox-wrapper-checked {
    &:hover {
      border-color: $primary-dark-green !important;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-dark-green !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $primary-dark-green;
  }

  .ant-checkbox-inner {
    border: 1px solid $primary-dark-green;
  }

  label {
    color: $primary-dark-green;
    font-weight: bold;
    width: 100%;
  }

  label::after {
    content: '';
  }

  .bench-form__map {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    outline: none;
  }

  input {
    border: 1px solid $primary-dark-green-lighter;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px 11px;
    width: 100%;
    outline: none;

    &::placeholder {
      color: rgba(0, 84, 80, 0.35);
    }
  }

  .offset-text {
    font-weight: bold;
    color: $primary-dark-green;
  }

  .offset_input {
    width: 50%;
  }
}

.ant-select-item-empty {
  color: #fff;
  text-align: center;
}
