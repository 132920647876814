@import '../../../assets/styles/index.scss';

.users-list__spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;

  .ant-empty {
    color: $primary-dark-green;
    font-weight: 500;
  }
}

.users-list__deletion-confirmation-modal {
  top: 30%;

  .ant-modal-body {
    padding: 24px !important;

    .ant-modal-confirm-title {
      color: $primary-dark-green !important;
    }

    .ant-btn {
      border-radius: 8px;
      border-color: $primary-light-green !important;

      &:hover {
        border-color: $primary-light-green !important;
      }
    }

    .ant-btn:first-child {
      color: $primary-light-green !important;

      &:hover {
        color: $primary-light-green-light !important;
        border-color: $primary-light-green-light !important;
      }
    }

    .ant-btn-primary {
      background: $primary-light-green !important;

      &:hover {
        background: $primary-light-green-light !important;
        border-color: $primary-light-green-light !important;
      }
    }
  }
}

.users-list__cards-container {
  height: 100%;
  padding-bottom: 20px;
  flex-flow: wrap;

  .users-list__card {
    margin: 10px;
    box-shadow: 0px 5px 10px rgba(0, 84, 80, 0.1);

    .users-list__table {
      width: 100%;
      background: #fff;

      .users-list__table-cell {
        color: $primary-dark-green;
        font-weight: 500;
      }
    }

    .ant-card-body {
      padding: 20px;
    }
  }
}

.users-list__actions {
  background: $primary-dark-green;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(0, 37, 35, 0.3);

  .ant-dropdown-menu-item {
    color: #fff;

    &:hover {
      color: $primary-light-green;
      background: $primary-dark-green-light !important;
    }
  }

  .users-list__actions-divider {
    margin: 0;
    height: 0.5px !important;
    width: auto;
    min-width: auto;
  }
}

.users-list__actions-dropdown-button {
  .ant-dropdown-trigger {
    transform: rotate(90deg);
    background: transparent;
    border: 0;
    color: $primary-dark-green;
  }
}

.user_status_container {
  display: flex;
  justify-content: center;

  .ant-select-selector {
    border-radius: 8px !important;
    background-color: $primary-dark-green !important;
    color: #fff !important;
    border: none !important;
    width: 92px !important;
    text-align: center;
  }
}

.select_status_wrapper {
  text-align: center;

  .select_option_element {
    border-bottom: 1px solid #00000034;
  }
}

@media screen and (min-width: 50px) {
  .users-list__table {
    .users-list__table-cell,
    .ant-tag-custom {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 430px) {
  .users-list__table {
    .users-list__table-cell,
    .ant-tag-custom {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 570px) {
  .users-list__table {
    .users-list__table-cell,
    .ant-tag-custom {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 720px) {
  .users-list__table {
    .users-list__table-cell,
    .ant-tag-custom {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 991px) {
  .users-list__tag-container {
    display: flex;
    align-items: flex-start;
  }
  .users-list__cards-container {
    .users-list__actions-dropdown-button {
      position: absolute;
      top: -60px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .users-list__table {
    .users-list__table-cell {
      font-size: 15px;
    }
  }
}
