@import '../../../../assets/styles/index.scss';

.card-list__container {
  overflow: auto;
  max-height: 140px;
  min-height: 140px;

  .card-list__empty-list-message {
    color: $primary-dark-green;
  }

  .card-list {
    border: 0;

    .card-list__item {
      color: $primary-dark-green;
      border: 0;
      border-bottom: 1px solid rgba(0, 84, 80, 0.15);
      padding-left: 0 !important;
      cursor: pointer;
    }
  }
}
