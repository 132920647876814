@import '../../../../../assets/styles/index.scss';

.client-branch-form__modal-footer {
    margin: 10px 0;
    height: 50px;
  
    .client-branch-form__modal-footer-cancel {
      color: $primary-light-green;
      cursor: pointer;
      font-weight: bold;
    }
}