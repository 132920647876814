@import "src/assets/styles/index";

.media-container {
  padding-bottom: 20px;
  * {
    width: 100%;
  }
  video {
    cursor: pointer;
  }
}