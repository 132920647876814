@import '../../../../../assets/styles/index.scss';

.request-form__modal-footer {
  margin: 20px 0;

  .request-form__modal-footer-cancel {
    color: $primary-light-green;
    cursor: pointer;
    font-weight: bold;
  }
}
