@import '../../../assets/styles/index.scss';

.landing-page-container {
  .landing-page__card {
    margin-top: 64px;

    .ant-card-body {
      padding: 0;
      height: 100%;
    }

    .landing-page-about-section {
      background: url('../../../assets/images/login_about_section.PNG');
      background-repeat: no-repeat;
      background-size: contain;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    .landing-page-to-map-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $primary-dark-green;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 30px 40px;

      .login-button-wrapper {
        width: 70%;
        margin-bottom: 32px;

        .to-login-button {
          font-size: 16px;
          height: 50px;
        }
      }

      .to-map-button-wrapper {
        width: 70%;

        .to-map-btn {
          background-color: #fff !important;
          color: $primary-dark-green;
          border: none;
          box-shadow: none;
          font-size: 16px;
          height: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .to-map-button-wrapper,
  .login-button-wrapper {
    width: 100% !important;
  }
}
