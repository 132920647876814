@import '../../../assets/styles/index.scss';

.forgot-password__container {
  .forgot-password__card {
    margin-top: 40px;
    height: 600px;

    .forgot-password__about-section {
      background: url('../../../assets/images/login_about_section.PNG');
      background-repeat: no-repeat;
      background-size: contain;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    .ant-card-body {
      padding: 0;
      height: 100%;
    }

    .forgot-password__form-container {
      background-color: $primary-dark-green;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 30px 40px;

      .forgot-password__login-title {
        color: $primary-light-green;
        font-size: 33px;
      }

      .forgot-password__action-description {
        margin-top: 170px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        display: flex;
        align-items: center;
        letter-spacing: 0.003em;

        color: #ffffff;
      }

      form {
        margin-top: 40px;
      }

      .ant-form-item {
        display: block;
      }

      .forgot-password__input {
        background-color: $primary-dark-green !important;
        border: 1px solid #ffffff35;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px;
        background-color: $primary-dark-green;
        color: #fff;
        font-weight: 500 !important;

        &::placeholder {
          color: #ffffff35;
        }

        .ant-input-password-icon {
          color: rgba(255, 255, 255, 0.35);

          &:hover {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      .ant-form-item-has-error .ant-input:not([disabled]),
      .ant-form-item-has-error .ant-input-affix-wrapper:not([disabled]) {
        border-color: #f00 !important;
      }

      .ant-form-item-required::before {
        font-size: 28px !important;
      }

      .ant-form-item-explain {
        font-weight: bold !important;
      }

      label {
        color: #fff;
      }

      label::after {
        content: '';
      }

      .forgot-password__footer {
        align-items: center;
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .forgot-password__container {
    .forgot-password__card {
      margin: 80px auto;
      height: 500px;
      .forgot-password__form-container {
        .forgot-password__login-title {
          text-align: center;
        }

        .forgot-password__action-description {
          margin-top: 70px;
        }

        form {
          margin-top: 30px;
        }

        .forgot-password__footer {
          margin-top: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .forgot-password__container {
    .forgot-password__card {
      .forgot-password__form-container {
        border-radius: 16px;
      }
    }
  }
}
