@import '../../../../../assets/styles/index.scss';

.branch-bench-form__modal-footer {
    margin: 10px 0;
    height: 50px;
  
    .branch-bench-form__modal-footer-cancel {
      color: $primary-light-green;
      cursor: pointer;
      font-weight: bold;
    }
}